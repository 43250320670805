<template>
  <div class="wrapper">
    <div class="cancel">
      <router-link to="/">
        <CloseIcon />
      </router-link>
    </div>

    <div class="auth-banner">
      <img
        :src="
          this.currentLanguage === 'fr'
            ? '/img/banners/login-banner-fr.png'
            : '/img/banners/login-banner-en.png'
        "
        style="width: 100%; border-radius: 10px; max-width: 100%"
      />
    </div>

    <section class="p-4">
      <div style="padding: 5px">
        <h4 class="text-dark text-center activate_your_account">
          {{ $t("activateAccount") }}
          <!-- <span class="text-dark">{{ $t("code") }}</span> -->
        </h4>
        <p class="verify_phone" style="padding: 0 10px">
          {{ $t("verifyPhoneNumber") }}
          <!-- <strong>+{{ this.msisdn || "--" }} </strong> -->
          <strong> +{{ formatPhoneNumber(msisdn) || "--" }} </strong>
        </p>

        <p
          @click="handlePhoneNumberEdit"
          style="
            text-transform: capitalize;
            text-align: center;
            font-size: 10px;
            cursor: pointer;
            font-weight: 700;
            color: var(--slide-active);
          "
        >
          {{ $t("editPhoneNumber") }}
        </p>
      </div>

      <div class="form-wrapper mb-1">
        <form>
          <div class="otp-container">
            <input
              v-for="(value, index) in code"
              :key="index"
              class="otp-input"
              type="tel"
              inputmode="numeric"
              maxlength="1"
              v-model="code[index]"
              @input="handleInput(index, $event)"
              @keydown="blockInvalidChar"
              @keydown.backspace="handleBackspace(index)"
            />
          </div>
          <small class="text mb-3">{{ $t("enter4DigitCode") }}</small>
        </form>
      </div>

      <div>
        <p
          v-if="spinner"
          style="text-transform: capitalize; text-align: center"
        >
          {{ $t("loading") }}...
        </p>

        <div
          v-if="allowResendTimer"
          class="login-button-wrapper text-center p-3 mb-3"
        >
          <div
            class="text-center mb-3 allow_resend_text"
            :style="{ color: resendSecond > 0 ? 'gray' : 'var(--dark-red)' }"
          >
            {{ $t("resendCodein") }} {{ this.resendSecond }} {{ $t("seconds") }}
          </div>
        </div>
        <div
          v-if="allowResend && !spinner && !allowResendTimer"
          class="login-button-wrapper text-center p-3 mb-3"
        >
          <div
            class="text-center mb-3 allow_resend_text"
            style="color: var(--dark-red)"
            @click="handleTokenResend"
          >
            <span>{{ $t("resendCodeText") }}</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "@/services/identity";
import { pushToDataLayer } from "@/utils/gtm";
import { mapState } from "vuex";
import CloseIcon from "../components/CloseIcon.vue";

export default {
  name: "Account-Activation",
  comments: { CloseIcon },
  data: function () {
    return {
      first_name: "",
      last_name: "",
      msisdn: "",
      code: new Array(4).fill(""),
      error: [],
      warning: [],
      success: [],
      loading: "",
      spinner: false,
      allowResend: false,
      allowResendTimer: true,
      resendSecond: 60,
      btnText: "/img/icons/eye.svg",
      btnText2: "/img/other/eye.svg",
      btnText3: "/img/other/eye.svg",
      showSuccessModal: false,
      countdownInterval: null,
    };
  },
  watch: {
    code: {
      handler() {
        if (this.code.join("").length === 4) {
          this.handleVerifyClick();
          this.allowResendTimer = true;
          if (this.resendSecond === 0) {
            this.startCountdown();
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    },
    phoneInvalid: function () {
      if (this.msisdn.length < 10 && this.msisdn.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    ...mapState(["referralcode"]),
  },
  methods: {
    formatPhoneNumber(msisdn) {
      return msisdn.replace(/^(\d{3})(\d{3})(\d{3})(\d{4})$/, "$1 $2 $3 $4");
    },
    trackResetButtonClick(event) {
      pushToDataLayer("gtm.reset", {
        category: "Button",
        action: "Click",
        label: "Reset",
        element: event.target,
      });
    },
    blockInvalidChar(e) {
      if (["e", "E", "+", "-", "."].includes(e.key)) {
        e.preventDefault();
      }
    },
    handlePhoneNumberEdit() {
      this.$router.push("/join");
    },

    startCountdown() {
      this.resendSecond = 60;
      this.allowResend = false;
      this.allowResendTimer = true;

      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }
      this.countdownInterval = setInterval(() => {
        this.resendSecond--;

        if (this.resendSecond === 0) {
          clearInterval(this.countdownInterval);
          this.allowResend = true;
          this.allowResendTimer = false;
        }
      }, 1000);
    },

    handleVerifyClick() {
      this.verifyAccount();
    },
    handleTokenResend() {
      this.tokenResend();
    },

    handleInput(index, event) {
      const value = event.target.value;
      this.code[index] = value.replace(/[^0-9]/g, "").slice(0, 1);
      if (event.target.value.length === 1) {
        const nextInput = this.$el.querySelectorAll(".otp-input")[index + 1];
        if (nextInput) nextInput.focus();
      }
    },
    handleBackspace(index) {
      if (this.code[index] === "") {
        const prevInput = this.$el.querySelectorAll(".otp-input")[index - 1];
        if (prevInput) prevInput.focus();
      }
    },

    verifyAccount: function () {
      this.reset();
      const msisdn = localStorage.getItem("mssdn__reg_xpk");

      if (this.code.length < 4) {
        this.setError(`${this.$t("validVerificationCode")}`);
        return;
      }
      if (this.msisdn === null) {
        this.setError(`${this.$t("invalidPhoneNumber")}`);
        return;
      }

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = `${process.env.VUE_APP_URL_VERIFY}?lang=${this.$i18n.locale}`;
      var currentDate = new Date();
      var login_tag = parseInt(
        currentDate.getTime() +
          "" +
          Math.floor(Math.random() * (9 * Math.pow(10, 2))) +
          Math.pow(10, 2)
      );
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source") || "";
      var utm_medium = this.getValue("utm_medium") || "";
      var utm_campaign = this.getValue("utm_campaign") || "";
      const fullCode = this.code.join("");

      var payload = {
        phone_number: msisdn,
        code: parseInt(fullCode),
        login_tag: login_tag,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer,
      };

      axios
        .post(path, payload, {
          headers: {
            "Content-Type": "application/json",
            lang: this.$i18n.locale || "fr",
          },
        })
        .then((res) => {
          vm.spinner = false;
          vm.loading = "";
          var profile = res.data;

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          vm.setSuccess(
            `${this.$t("successfullAccountVerification")}`
          );

          // console.log(profile);

          if (parseInt(vm.placeBet) === 1) {
            vm.setValue("placeBet", 0);
            vm.EventBus.$emit("event:betslip:show");
          } else {
            setTimeout(() => {
              this.saveObject("freebet-first", true);
              window.location.href = "/free-bet";
              // window.location.href = "/";
            }, 2000);
          }
        })
        .catch((err) => {
          vm.spinner = false;
          vm.loading = "";
          console.log("Error:", err);
          console.log("err fd", err);

          if (err.response) {
            this.setError(err.response.data.error_message);
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log("Error Request:", err.request);
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log("Error Message:", err.message);
          }
        });
    },
    tokenResend: function () {
      this.reset();
      const msisdn = localStorage.getItem("mssdn__reg_xpk");

      if (this.msisdn === null) {
        this.setError(`${this.$t("invalidPhoneNumber")}`);
        return;
      }

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = `${process.env.VUE_APP_BASE_IDENTITY_URL}/token/resend?lang=${this.$i18n.locale}`;
      var currentDate = new Date();
      var login_tag = parseInt(
        currentDate.getTime() +
          "" +
          Math.floor(Math.random() * (9 * Math.pow(10, 2))) +
          Math.pow(10, 2)
      );
      this.setValue("login_tag", login_tag);

      var payload = {
        phone_number: `${msisdn}`,
      };

      axios
        .post(path, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var profile = res.data;

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);
          vm.spinner = false;
          vm.loading = "";

          vm.startCountdown();

          vm.$nextTick(() => {
            vm.setSuccess(`${res?.data?.message || this.$t("resentOtp")}`);
          });
        })
        .catch((err) => {
          vm.spinner = false;
          vm.loading = "";
          console.log("Error:", err);
          console.log("err fd", err);

          if (err.response) {
            console.log("Error Response Data:", err.response.data);
            console.log("Error Response Status:", err.response.status);
            console.log("Error Response Headers:", err.response.headers);

            this.setError(err.response.data.error_message);
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log("Error Request:", err.request);
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log("Error Message:", err.message);
          }
        });
    },
  },
  mounted: function () {
    this.startCountdown();
    const msisdn = localStorage.getItem("mssdn__reg_xpk");
    this.msisdn = msisdn;
  },
};
</script>

<style src="./index.css" scoped></style>
